import React, { useEffect, useState } from "react";
import ToolTip from "@components/toolTip/toolTip";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import RoundInfoItem from "@components/history/RoundHistory/RoundInfoItem";
import CardList from "@components/history/RoundHistory/CardList";
import { FormattedMessage } from "react-intl";
import { CardsSuit, PlayerOddType, CardTypeMap } from "@constants/Enums";
import { ModalCloseIcon } from "@resource";
import classNames from "classnames";
import { gameHistory } from "@constants/Enums";
import Modal from "@components/modal";

function GameHistoryLastResult({ round, ...restProps }) {
    const [showModal, setShow] = useState(false);
    const [activeRound, setActiveRound] = useState(false);
    const [player8Cards, setPlayer8Cards] = useState([]);
    const [player9Cards, setPlayer9Cards] = useState([]);
    const [player10Cards, setPlayer10Cards] = useState([]);
    const [player11Cards, setPlayer11Cards] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (round.Hands) {
            let cardsEight = [],
                cardsNine = [],
                cardsTen = [],
                cardsEleven = [];
            if (round.Hands.player8Hand) {
                cardsEight.push(round.Hands.player8Hand);
            }
            if (round.Hands.player9Hand) {
                cardsNine.push(round.Hands.player9Hand);
            }
            if (round.Hands.player10Hand) {
                cardsTen.push(round.Hands.player10Hand);
            }
            if (round.Hands.player11Hand) {
                cardsEleven.push(round.Hands.player11Hand);
            }
            // console.log('cardsEight',cardsEight)            
            // console.log('cardsNine',cardsNine)            
            // console.log('cardsTen',cardsTen)            
            // console.log('cardsEleven',cardsEleven)            
            // round.forEach((card, index) => {
            // console.log('card',card)
            //             const cardItem = card.split(",");
            //             const cardString = cardItem[0] + CardsSuit[cardItem[1]];
            //             if (index % 2 !== 0) {
            //                 cardsB.push(cardString);
            //             } else {
            //                 cardsA.push(cardString);
            //             }
            // });
            setPlayer8Cards(cardsEight[0]);
            setPlayer9Cards(cardsNine[0]);
            setPlayer10Cards(cardsTen[0]);
            setPlayer11Cards(cardsEleven[0]);
        }
    }, [round]);

    const trigger = (isActive) => {
        setActiveRound(isActive);
    };

    const getContent = () => {
        return (
            <div className="game-history-last-results-item cards-32-game-hisory-last-result-item">
                <div className="game-history-last-results-item-header">
                    <div className="title">
                        <FormattedMessage id="round_id" />: {round.RoundId}
                    </div>
                    <button className="circled-button small" onClick={() => handleClose()}>
                        <ModalCloseIcon
                            id={`GameHistoryLastResult${round.RoundId}`}
                        />
                    </button>
                </div>
                <div className="game-history-last-results-item-round-info">
                    <RoundInfoItem
                        time={round.StartTime}
                        timeTitle="game_start"
                    />
                    <RoundInfoItem time={round.EndTime} timeTitle="game_end" />
                </div>
                <div className="game-history-last-results-item-round-cards ">
                    <CardList
                        player={<FormattedMessage id="player_8" />}
                        cards={player8Cards}
                        winner={
                            round.Won === PlayerOddType.Player8
                        }
                    />

                    <CardList
                        player={<FormattedMessage id="player_9" />}
                        cards={player9Cards}
                        winner={
                            round.Won === PlayerOddType.Player9
                        }
                    />
                    <CardList
                        player={<FormattedMessage id="player_10" />}
                        cards={player10Cards}
                        winner={
                            round.Won === PlayerOddType.Player10
                        }
                    />

                    <CardList
                        player={<FormattedMessage id="player_11" />}
                        cards={player11Cards}
                        winner={
                            round.Won === PlayerOddType.Player11
                        }
                    />
                </div>
            </div>
        );
    };
    return (
        // <ToolTip
        //     title={getContent()}
        //     position={"top"}
        //     type="popup"
        //     triggerCallBack={trigger}
        //     {...restProps}
        // >
        <div
            className={classNames("game-history-icon large", "player-" + gameHistory[round.Won], {
                active: { activeRound },
            })}
            onClick={handleShow}
        >
            {gameHistory[round.Won]}
            <Modal show={showModal}
                handleCancel={() => handleClose()}
                parentClass={"game-last-history"}
            >
                <div className="modal-header">
                    <div className="modal-header-title">
                        
                    </div>
                    <div className="modal-header-control">
                        {/* <button className="circled-button small" onClick={() => handleClose()}>
                            <ModalCloseIcon id="History" />
                        </button> */}
                    </div>
                </div>
                <div className="game-last-history">
                    {getContent()}
                </div>
            </Modal>
        </div>
        // <GameHistoryIcon
        //     won={round.Won}
        //     type="large"
        //     round={round}
        //     active={activeRound}
        // />
        // </ToolTip>
    );
}

export default GameHistoryLastResult;
