import {
    SET_CARDS,
    SUB_GAME_FINISH,
    RESET_USER_BET_LIST,
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SET_LOAD_INFO,
    SET_ODDS,
    SUB_CANCEL_GAME,
    SET_TIME_TICKER,
    SET_ALERT_MSG,
    REQUEST_REFRESH_SESSION,
    REQUEST_GAME_START,
    CONNECT_TO_SOCKET,
    SUB_GAME_INIT,
    SET_GAME_INITIAL_DATA,
    SET_ALL_BETS,
    SET_USER_INFO,
    SET_VIDEO_STREAM_CONFIG,
    SET_DISABLE_BETS_AREAS,
    SET_LIMIT,
    SET_USER_WON,
    SUB_GAME_RESULT,
    SET_WINNER_LIST
} from "@actions/actionTypes";
import {CardsSuit, PlayerOddType} from "@constants/Enums";
import initialStore from "../initialStore";
import { getRealOdd } from "@services/oddService";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@services/storageService";

function getCardsModel(cards) {
    /*return cards.map(item => {
        const cardItem = item.split(",")
        return  cardItem[0] + CardsSuit[cardItem[1]]
    })*/
    console.log("cARDS: ", cards)
    return cards;
}

export function subscribeGameFinish() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_GAME_FINISH,
            handle: () => {
                const {userInfo, roundId} = getState();
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`)
                dispatch({type: SET_CARDS, card: []});
                dispatch({type: RESET_USER_BET_LIST});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
                dispatch({type: SET_ODDS, odds: initialStore.odds});
                dispatch({type: SET_DISABLE_BETS_AREAS});
                dispatch({type: SET_ALL_BETS, bets: []});
            }
        })
    }
}

export function subscribeGameResult() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_RESULT,
            handle: (data) => {
                dispatch({type: SET_USER_WON, wonAmount: {value: data.WonAmount}});
                dispatch({type: SET_WINNER_LIST, winners: data.Results});
            }
        })
    }
}

export function setUserWon(wonAmount) {
    return dispatch => {
        dispatch({type: SET_USER_WON, wonAmount})
    }
}

export function subscribeCanceledGame() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_CANCEL_GAME,
            handle: (data) => {
                const {userInfo} = getState();
                dispatch({type: SET_CARDS, card: []});
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
                dispatch({type: SET_ODDS, odds: initialStore.odds});
                dispatch({type: SET_TIME_TICKER, ticker: initialStore.timeTicker});
                dispatch({type: SET_ALERT_MSG, data: {type: 'info', messages: "game_is_canceled_your_bets_will_refund"}});
                dispatch({type: SET_ALL_BETS, bets: []});
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${data.roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${data.roundId}`)
            }
        })
    }
}

export function refreshSession(session) {
    return dispatch => {
        dispatch({
            type: REQUEST_REFRESH_SESSION,
            session
        })
    }
}

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(session) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            session
        })
    }
}

export function subGameInitData() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_INIT,
            handle: (data) => {
                let unAcceptedBets = [], bets = []
                data.Cards && (data.Cards.Codes = getCardsModel(data.Cards.Codes));
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Timer?.Start || 0, End: data.Timer?.End || 0}});
                dispatch({type: SET_ODDS, odds: data.RoundStart ? getRealOdd(data.RoundStart.Odds) : {} });
                dispatch({type: SET_GAME_INITIAL_DATA, ...data});
                dispatch({type: SET_USER_INFO, user: data.User});
                dispatch({type: SET_LOAD_INFO, load: data.Load?.Load || initialStore.loadInfo});
                dispatch({type: SET_VIDEO_STREAM_CONFIG, config: data.Video});
                if(!(data.Odd && data.Odd.End && data.Odd.Time < data.Odd.End)) {
                    dispatch({type: SET_DISABLE_BETS_AREAS})
                }
                dispatch({type: SET_LIMIT, limit: data.Limit})
                if (data.User.Id && data.RoundStart?.RoundId) {
                    unAcceptedBets = getLocalStorage(`unAcceptedBets_${data.User.Id}_${ data.RoundStart.RoundId}`) || []
                }
                if (data.PlayerBets?.Bets.length) {
                    data.PlayerBets.Bets.forEach(bet => {
                        bets.push({
                            accepted: true,
                            Prediction: bet.Prediction,
                            Stake: bet.Stake,
                            Odd: bet.Odd})
                    })
                }
                if (data.RoundStart?.RoundId && data.User?.Id) {
                    setLocalStorage(`acceptedBets_${data.User.Id}_${data.RoundStart.RoundId}`, bets)
                }
                dispatch({type: SET_ALL_BETS, bets: [...unAcceptedBets, ...bets], startTime:  data.Timer?.Start});
            }
        })
    }
}