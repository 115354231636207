import React from "react";
import CardsItem from "@components/Cards/CardsItem";
import cardequel from "../../assets/img/icons/icon-equel.svg";
import cardplus from "../../assets/img/icons/icon-plus.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Navigation } from "swiper";
import {PrevButtonIcon, NextButtonIcon} from "@resource";

function CardsList({ cardList, title, cardNumber }) {

    return (
        <div className="cards-element">
            <div className="cards-top-box">
                <div className="title">{title}</div>
                <ul className="cards-list">
                   
                    <li className="cards-list-item card">
                        <div className="card_number_title">{cardNumber}</div>
                    </li>
                </ul>
                <div className="plus-cards">
                    <img src={cardplus} />
                </div>
            </div>
            <div className="cards-middle-box">
                {/* <div className="card-box-container"> */}
                <div className="cards-slider">
                    {
                    cardList?.Cards?.length > 1 || cardList?.cards?.length > 1 ? (
                    <>
                    <span className={`swiper-button arrow-${cardNumber}-cards-prev`}>
                        <PrevButtonIcon />
                    </span>
                    <Swiper
                        slidesPerView={"auto"}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: `.arrow-${cardNumber}-cards-prev`,
                            nextEl: `.arrow-${cardNumber}-cards-next`,
                        }}>
                        {
                            cardList && cardList.Cards ? cardList.Cards.slice(0).reverse().map((card, index) => (
                                <SwiperSlide key={index}>
                                    <CardsItem card={card}/>
                                </SwiperSlide>
                            )) 
                            :
                            cardList && cardList.cards ? cardList.cards.slice(0).reverse().map((card, index) => (
                                <SwiperSlide key={index}>
                                    <CardsItem card={card}/>
                                </SwiperSlide>
                            ))
                            :
                            <SwiperSlide>
                                <CardsItem card={[]}/>
                            </SwiperSlide>
                        }
                    </Swiper>
                    <span className={`swiper-button arrow-${cardNumber}-cards-next`}>
                        <NextButtonIcon  />
                    </span></>) : <Swiper
                        slidesPerView={"auto"}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: `.arrow-${cardNumber}-cards-prev`,
                            nextEl: `.arrow-${cardNumber}-cards-next`,
                        }}>
                        {
                            cardList && cardList.Cards ? cardList.Cards.slice(0).reverse().map((card, index) => (
                                <SwiperSlide key={index}>
                                    <CardsItem card={card}/>
                                </SwiperSlide>
                            )) 
                            :
                            cardList && cardList.cards ? cardList.cards.slice(0).reverse().map((card, index) => (
                                <SwiperSlide key={index}>
                                    <CardsItem card={card}/>
                                </SwiperSlide>
                            ))
                            :
                            <SwiperSlide>
                                <CardsItem card={[]}/>
                            </SwiperSlide>
                        }
                    </Swiper>
                    }
                </div>
                {/* </div> */}
                {/* <CardsItem card={cardList && cardList.Cards ? cardList.Cards.length - 1 : cardList && cardList.cards ? cardList.cards.length - 1: []} /> */}
            </div>
            <div className="cards-bottom-box">
                <div className="plus-cards">
                    <img src={cardequel} />
                </div>
                <div>
                    
                    <ul className="cards-list">
                        <li className="cards-list-item card">
                            <div className="card_number_title">{(cardList && cardList.CardsWeight) || (cardList && cardList.cardsWeight)}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CardsList;
