import {SET_CARDS, SET_PAIR, SUB_CARDS, SET_SOUND_KEY} from "@actions/actionTypes";
import {CardsSuit, PlayerOddType} from "@constants/Enums";

export function subscribeCards() {
    return dispatch => {

        dispatch({
            eventOn: SUB_CARDS,
            handle: (data) => {

                /*const card = data.Codes.map((item,index) => {
                    const cardItem = item.split(",")
                    if (index === data.Codes.length - 1) {
                        dispatch({type: SET_SOUND_KEY, payload: cardItem[0] + CardsSuit[cardItem[1]]})
                    }
                    return  cardItem[0] + CardsSuit[cardItem[1]]
                    }
                )*/
                console.log("CARD DATA: ", data)
                dispatch({type: SET_CARDS, card: data});
                //dispatch({type: SET_PAIR, pair:  PlayerOddType.None});
            }
        })
    }
}

export function resetPlayingCards() {
    return dispatch => {
        dispatch({type: SET_CARDS, card: []})
    }
}