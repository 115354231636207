import {SET_BACKGROUND_MUSIC, SET_VOLUME} from "@actions/actionTypes"

export default function backgroundMusicReducer(state = false, action) {
    switch (action.type) {
        case SET_BACKGROUND_MUSIC:
            return  action.payload
        case SET_VOLUME:
            return action.payload === "0" ? false : true
        default:
            return state;
    }
}