import {DefaultLanguageCode} from "@constants/Enums";

export default {
    settings: {
        lang: DefaultLanguageCode
    },
    emptyObj: {},
    emptyArr: [],
    globalInfo: {
        isConnected: false,
        session:"",
        lobbyUrl: "",
        detectNewLogin: false
    },
    totalBets: 0,
    winnerList: [], //getWinnerList(),
    history: {
        data: [],
        hasNext: false,
    },
    odds: {
        0: undefined,
        1: undefined,
        3: undefined,
        4: undefined,
    },
    loadInfo: {
      "A": null,
      "B": null
    },
    userBetList: [],

    cards: {
        player8Hand: {},
        player9Hand: {},
        player10Hand: {},
        player11Hand: {}
    },
    EnabledBetAreas: {
        "Back8": true,
        "Lay8": true,
        "Back9": true,
        "Lay9": true,
        "Back10": true,
        "Lay10": true,
        "Back11": true,
        "Lay11": true,
    },
    timeTicker: {
        diff: 0,
        start: 0,
        end: 0
    },
    gameHistory: {
        history: [],
        Player8Percentage: 0,
        Player9Percentage: 0,
        Player10Percentage: 0,
        Player11Percentage: 0
    },
    userInfo: {
        Balance: 0,
        Currency: "",
        Id: "",
        Image: null,
        Name: ""
    },
    limit: {
        Max: 0,
        Min: 0,
        Nominal: []
    },
    selectedBoard: {
        board: -1,
        odd:0
    },
    loadInfo: {
        "Player8": null,
        "Player9": null,
        "Player10": null,
        "Player11": null
      },
}

function getWinnerList() {
    let result = [];
    for (let i = 0; i < 100; i++) {
        result.push({
            PlayerName: (Math.random() + 1).toString(36).substring(Math.random() * 10) + i,
            WonAmount: Math.round(Math.random() * 100000)
        })
    }
    return result
}