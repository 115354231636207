import {
    SET_TIME_TICKER,
    SUB_TIME_TICKER,
    SET_DISABLE_BETS_AREAS
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export function subscribeTimeTicker() {
    return dispatch => {
        dispatch({
            eventOn: SUB_TIME_TICKER,
            handle: (data) => {
                dispatch({type: SET_TIME_TICKER, ticker: data});
            }
        })
    }
}


export function disabledBetsAreas() {
    return dispatch => {
        dispatch({type: SET_DISABLE_BETS_AREAS})
    }
}

export function resetTimeTicker() {
    return dispatch => {
        dispatch({type: SET_TIME_TICKER, ticker: initialStore.timeTicker})
    }
}