import {
    SET_VIDEO_STREAM_CONFIG,
    SET_GAME_INITIAL_DATA
} from "@actions/actionTypes";

export default function videoStreamConfigReducer(state = "", action) {
    switch (action.type) {
        case SET_VIDEO_STREAM_CONFIG:
            return action.config;
        case SET_GAME_INITIAL_DATA:
            return action.Video;
        default:
            return state;
    }
}