import initialStore from "../initialStore";
import {
    SET_LOAD_INFO,
    SET_GAME_INITIAL_DATA
} from "@actions/actionTypes";

export default function totalBetsReducer(state = initialStore.loadInfo, action) {
    switch (action.type) {
        case SET_LOAD_INFO:
            return action.load;
        case SET_GAME_INITIAL_DATA:
            return action.Load?.Load || initialStore.loadInfo;
        default:
            return state;
    }
}
