import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import { subscribeGameHistory, getGameHistory } from "@actions/gameInfoAction";
import GameHistoryHeader from "@components/gameHistory/gameHistoryHeader";
import GameHistoryTable from "@components/gameHistory/gameHistoryTable";
import { FormattedMessage } from "react-intl";
import GameHistoryLastResult from "@components/gameHistory/gameHistoryLsatresult";

function GameHistory({
    history,
    isConnected,
    subscribeGameHistory,
    gameCondition,
    getGameHistory,
    Player8Percentage,
    Player9Percentage,
    Player10Percentage,
    Player11Percentage
}) {
    const [limit] = useState(84);
    const [skip] = useState(0);

    useEffect(() => {
        if (isConnected) {
            subscribeGameHistory();
            getGameHistory(limit, skip);
        }
    }, [isConnected]);

    useEffect(() => {
        if (isConnected) {
            getGameHistory(limit, skip);
        }
    }, [limit, skip, gameCondition]);

    return (
        <div className="game-history cards-32-game-history">
            <div className="game-history-last-results">
                {history.slice(0, 10).map((item, index) => (
                    <GameHistoryLastResult round={item} key={index} />
                ))}
                {/* <div className="tooltip-wrapper ">
                    <div className="game-history-icon large A active">8</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large B ">9</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large C ">10</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large D ">11</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large A ">8</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large B ">9</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large C ">10</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large D ">11</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large A ">8</div>
                </div>
                <div className="tooltip-wrapper ">
                    <div className="game-history-icon large B ">9</div>
                </div> */}
            </div>
            <GameHistoryHeader history={history} />
            <div className="game-history-data">
                <div className="info-box">
                    <div className="info-box-item">
                        <FormattedMessage id="player8" />{" "}
                        <span className="divider">-</span>{" "}
                        <span className="percent">{Player8Percentage}%</span>{" "}
                    </div>
                    <div className="info-box-item">
                        <FormattedMessage id="player9" />{" "}
                        <span className="divider">-</span>{" "}
                        <span className="percent">{Player9Percentage}%</span>
                    </div>
                    <div className="info-box-item">
                        <FormattedMessage id="player10" />{" "}
                        <span className="divider">-</span>{" "}
                        <span className="percent">{Player10Percentage}%</span>
                    </div>
                    <div className="info-box-item">
                        <FormattedMessage id="player 11" />{" "}
                        <span className="divider">-</span>{" "}
                        <span className="percent">{Player11Percentage}%</span>
                    </div>
                </div>
                <GameHistoryTable history={history} limit={limit} />
            </div>
        </div>
    );
}

const mapDispatchToProps = (state) => {
    return {
        isConnected: state.globalInfo.isConnected,
        gameCondition: state.gameCondition,
        history: state.gameHistory.history,
        Player8Percentage: state.gameHistory.Player8Percentage,
        Player9Percentage: state.gameHistory.Player9Percentage,
        Player10Percentage: state.gameHistory.Player10Percentage,
        Player11Percentage: state.gameHistory.Player11Percentage
    };
};

const mapPropsToState = {
    subscribeGameHistory,
    getGameHistory,
};

export default connect(mapDispatchToProps, mapPropsToState)(GameHistory);
