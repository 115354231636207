import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { subscribeOdds } from "@actions/oddsAction";
import { selectBoard, resetSelectBoard } from "@actions/betsAction";
import LargeBoard from "@components/betBoard/largeBoard";
import SmallBoard from "@components/betBoard/smallBoard";
import {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
} from "@actions/workerActions";
import Load from "@components/betBoard/load";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ResizeContext } from "../../contexts/resizeContext";

function Board({
    subscribeOdds,
    odds,
    enabledBetAreas,
    subscribeTickerWorker,
    unSubscribeTickerWorker,
    selectBoard,
    threeD,
    selectedBoard,
    resetSelectBoard,
    loadInfo
}) {
    const [disabledAll, setDisabledAll] = useState(false);
    const { mobile, tab } = useContext(ResizeContext);
    const classplayer8 = loadInfo['Player8'] > 0 ? 'green' : loadInfo['Player8'] < 0 ? 'red' : '';
    const classplayer9 = loadInfo['Player9'] > 0 ? 'green' : loadInfo['Player9'] < 0 ? 'red' : '';
    const classplayer10 = loadInfo['Player10'] > 0 ? 'green' : loadInfo['Player10'] < 0 ? 'red' : '';
    const classplayer11 = loadInfo['Player11'] > 0 ? 'green' : loadInfo['Player11'] < 0 ? 'red' : '';

    useEffect(() => {
        subscribeOdds();
        function getGameTimeDiff(e) {
            const timer = JSON.parse(e.data);
            if (!(timer.data && parseInt(timer.data / 1000) > 3)) {
                setDisabledAll(true);
            } else {
                setDisabledAll(false);
            }
        }
        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff);
        };
    }, []);

    useEffect(() => {
        if (disabledAll) {
            resetSelectBoard();
        }
    }, [disabledAll]);

    return (
        <div className="board board-32">
            <div className={classNames("board-info", { threeD })}>
                {/* <div className="board-info-sides">
                    <div className="title"><FormattedMessage id="player_a"/></div>
                    <div className="title"><FormattedMessage id="player_b"/></div>
                </div> */}

                {!mobile && (
                    <LargeBoard
                        enabledBetAreas={enabledBetAreas}
                        disabledAll={disabledAll}
                        odds={odds}
                        selectBoard={selectBoard}
                        selectedBoard={selectedBoard}
                    />
                )}

                {mobile && (
                    <SmallBoard
                        enabledBetAreas={enabledBetAreas}
                        disabledAll={disabledAll}
                        odds={odds}
                        selectBoard={selectBoard}
                        selectedBoard={selectedBoard}
                    />
                )}
            </div>
            <div className="book-show-bottom">
                <div className={`player-8-book ${classplayer8}`}>{loadInfo['Player8'] ? loadInfo['Player8'] : ''}</div>
                <div className={`player-9-book ${classplayer9}`}>{loadInfo['Player9'] ? loadInfo['Player9'] : ''}</div>
                <div className={`player-10-book ${classplayer10}`}>{loadInfo['Player10'] ? loadInfo['Player10'] : ''}</div>
                <div className={`player-11-book ${classplayer11}`}>{loadInfo['Player11'] ? loadInfo['Player11'] : ''}</div>
            </div>
            <Load threeD={threeD} />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        odds: state.odds,
        enabledBetAreas: state.enabledBetAreas,
        selectedBoard: state.selectedBoard,
        loadInfo: state.loadInfo
    };
}

const mapDispatchToProps = {
    subscribeOdds,
    unSubscribeTickerWorker,
    subscribeTickerWorker,
    selectBoard,
    resetSelectBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
