import React from "react";
import classNames from "classnames";
import { Prediction } from "@constants/Enums";
import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";

function LargeBoard({
    odds,
    disabledAll,
    enabledBetAreas,
    selectedBoard,
    selectBoard,
}) {
    const oddsData = odds;
    let Back8 = "", Lay8 = "", Back9 = "", Lay9 = "", Back10 = "", Lay10 = "", Back11 = "", Lay11 = "";
    const [Player8Back, setPlayer8Back] = useState("");
    const [Player8Lay, setPlayer8Lay] = useState("");
    const [Player9Back, setPlayer9Back] = useState("");
    const [Player9Lay, setPlayer9Lay] = useState("");
    const [Player10Back, setPlayer10Back] = useState("");
    const [Player10Lay, setPlayer10Lay] = useState("");
    const [Player11Back, setPlayer11Back] = useState("");
    const [Player11Lay, setPlayer11Lay] = useState("");

    useEffect(() => {
        if(oddsData[0] != undefined ){
            oddsData.forEach((element) => {
                if(element.Player == 0)
                {
                    Back8 = element.Back;
                    Lay8 = element.Lay;
                }
                if(element.Player == 1)
                {
                    Back9 = element.Back;
                    Lay9 = element.Lay;
                }
                if(element.Player == 2)
                {
                    Back10 = element.Back;
                    Lay10 = element.Lay;
                }
                if(element.Player == 3)
                {
                    Back11 = element.Back;
                    Lay11 = element.Lay;
                }
            });
        }
    setPlayer8Back(Back8);
    setPlayer8Lay(Lay8);
    setPlayer9Back(Back9);
    setPlayer9Lay(Lay9);
    setPlayer10Back(Back10);
    setPlayer10Lay(Lay10);
    setPlayer11Back(Back11);
    setPlayer11Lay(Lay11);
    }, [oddsData]);

    return (
        <svg
            id="Component_463_1"
            data-name="Component 463 – 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 76"
        >
            <g id="Group_4348" data-name="Group 4348">
                <text
                    id="_8"
                    className="fontcaptain"
                    data-name="8"
                    transform="translate(30 51)"
                    fill="#fff"
                    fontSize="36"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-8.118" y="0">
                        8
                    </tspan>
                </text>
                <g
                    id="Group_4350"
                    data-name="Group 4350"
                    transform="translate(2 25)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player8Lay == "",
                        active: Player8Lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player8, Player8Lay)}
                >
                    <rect
                        id="Rectangle_12507"
                        data-name="Rectangle 12507"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 27)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9"
                        data-name="99.9"
                        transform="translate(28 43)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player8Lay || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4349"
                    data-name="Group 4349"
                    transform="translate(2 26)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player8Back == "",
                        active: Player8Back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player8, Player8Back)}
                >
                    <rect
                        id="Rectangle_12506"
                        data-name="Rectangle 12506"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 -26)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-2"
                        data-name="99.9"
                        transform="translate(28 -10)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                            { Player8Back || ""}
                        </tspan>
                    </text>
                </g>
            </g>
            <g id="Group_4351" data-name="Group 4351" transform="translate(80)">
                <text
                    id="_9"
                    className="fontcaptain"
                    data-name="9"
                    transform="translate(30 51)"
                    fill="#fff"
                    fontSize="36"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-7.704" y="0">
                        9
                    </tspan>
                </text>
                <g
                    id="Group_4350-2"
                    data-name="Group 4350"
                    transform="translate(2 25)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player9Lay == "",
                        active: Player9Lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player9, Player9Lay)}
                >
                    <rect
                        id="Rectangle_12507-2"
                        data-name="Rectangle 12507"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 27)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-3"
                        data-name="99.9"
                        transform="translate(28 43)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player9Lay || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4349-2"
                    data-name="Group 4349"
                    transform="translate(2 26)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player9Back == "",
                        active: Player9Back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player9, Player9Back)}
                >
                    <rect
                        id="Rectangle_12506-2"
                        data-name="Rectangle 12506"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 -26)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-4"
                        data-name="99.9"
                        transform="translate(28 -10)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player9Back || ""}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                id="Group_4352"
                data-name="Group 4352"
                transform="translate(160)"
            >
                <text
                    id="_10"
                    className="fontcaptain"
                    data-name="10"
                    transform="translate(30 51)"
                    fill="#fff"
                    fontSize="36"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-13.788" y="0">
                        10
                    </tspan>
                </text>
                <g
                    id="Group_4350-3"
                    data-name="Group 4350"
                    transform="translate(2 25)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player10Lay == "",
                        active: Player10Lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player10, Player10Lay)}
                >
                    <rect
                        id="Rectangle_12507-3"
                        data-name="Rectangle 12507"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 27)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-5"
                        data-name="99.9"
                        transform="translate(28 43)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player10Lay || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4349-3"
                    data-name="Group 4349"
                    transform="translate(2 26)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player10Back == "",
                        active: Player10Back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player10, Player10Back)}
                >
                    <rect
                        id="Rectangle_12506-3"
                        data-name="Rectangle 12506"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 -26)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-6"
                        data-name="99.9"
                        transform="translate(28 -10)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player10Back || ""}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                id="Group_4353"
                data-name="Group 4353"
                transform="translate(240)"
            >
                <text
                    id="_11"
                    className="fontcaptain"
                    data-name="11"
                    transform="translate(30 51)"
                    fill="#fff"
                    fontSize="36"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-11.232" y="0">
                        11
                    </tspan>
                </text>
                <g
                    id="Group_4350-4"
                    data-name="Group 4350"
                    transform="translate(2 25)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player11Lay == "",
                        active: Player11Lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player11, Player11Lay)}
                >
                    <rect
                        id="Rectangle_12507-4"
                        data-name="Rectangle 12507"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 27)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-7"
                        data-name="99.9"
                        transform="translate(28 43)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player11Lay || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4349-4"
                    data-name="Group 4349"
                    transform="translate(2 26)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player11Back == "",
                        active: Player11Back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player11, Player11Back)}
                >
                    <rect
                        id="Rectangle_12506-4"
                        data-name="Rectangle 12506"
                        width="60"
                        height="24"
                        rx="8"
                        transform="translate(-2 -26)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-8"
                        data-name="99.9"
                        transform="translate(28 -10)"
                        fontSize="12"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-14.554" y="0">
                        { Player11Back || ""}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}

export default LargeBoard;
