import {
    SET_ODDS,
    SET_DISABLE_BETS_AREAS
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {Prediction, PlayerOddType} from "@constants/Enums"

export default function enabledBetAreasReducer(state = initialStore.EnabledBetAreas, action) {
    switch (action.type) {
        case SET_ODDS:
            const enabledState =  JSON.parse(JSON.stringify(state))
            action.odds[Prediction.ABack] !== undefined ?  enabledState.ABack = false : enabledState.ABack = true
            action.odds[Prediction.ALay] !== undefined ?  enabledState.ALay = false : enabledState.ALay = true
            action.odds[Prediction.BBack] !== undefined ?  enabledState.BBack = false : enabledState.BBack = true
            action.odds[Prediction.BLay] !== undefined ?  enabledState.BLay = false : enabledState.BLay = true
            if (action.pair === PlayerOddType.None) {
                enabledState.BPair = true
                enabledState.APair = true
            }  else if (action.pair === PlayerOddType.Both) {
                enabledState.BPair = false
                enabledState.APair = false
            }
            return enabledState
        case SET_DISABLE_BETS_AREAS:
            return initialStore.EnabledBetAreas
        default:
            return state;
    }
}
