import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_GAME_START,
    SET_ALERT_MSG,
    SET_GAME_START,
    REQUEST_BET_HISTORY,
    SET_HISTORY
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";
import {getTokenByUrl, getLobbyUrlByUrl, PostResource, GetResource} from "@utils/HttpUtil";

function* fetchStartGameViaTembo() {
    const token = getTokenByUrl();
    const lobby = getLobbyUrlByUrl();

    if (token) {
        const res = yield call(PostResource, `/game/start`,{token})
        yield put( {type: SET_GAME_START, session: res.session, lobbyUrl: `${lobby}?token=${token}`})
    } else {
        yield put( {type: SET_ALERT_MSG, data: {type: 'error', messages: "token_not_found"}})
    }
}

function* fetchBetHistory({data}) {
    const token = getTokenByUrl();
    const history = yield call(GetResource, `/History?limit=${data.limit}&page=${data.page}&token=${token}`);
    yield put({type: SET_HISTORY, items: history, hasNext: true});
}

export default function* () {
    yield takeLatest(REQUEST_GAME_START, safe(onError, fetchStartGameViaTembo));
    yield takeLatest(REQUEST_BET_HISTORY, safe(onError, fetchBetHistory));
}