import React from "react";
import CardsList from "@components/Cards/CardsList";
import classNames from "classnames";

function CardList({ player, cards, winner = false }) {
    let cardNumber = "";
    if(player.props.id == 'player_8')
    {
        cardNumber = '8';
    }
    if(player.props.id == 'player_9')
    {
        cardNumber = '9';
    }
    if(player.props.id == 'player_10')
    {
        cardNumber = '10';
    }
    if(player.props.id == 'player_11')
    {
        cardNumber = '11';
    }
    return (
        <div className={classNames("history-card-list", { active: winner })}>
            {/* <div>
                <div className="player">{ player }</div>
            </div> */}
            <div>
                <CardsList
                    cardList={cards}
                    cardNumber={cardNumber}
                    className="history-card-list-view"
                />
            </div>
        </div>
    );
}

export default CardList;
