import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {MusicOnIcon, MusicOffIcon} from "@resource";
import VolumController from "./volum";
import ToolTip from "@components/toolTip/toolTip";


function GameMusic({ isOn}) {
    return(
        <li className={classNames(`template-button-item hybrid-item music`)}>
            <ToolTip title={<VolumController />} position={"bottom"} type="popup" className="music">
                {isOn ? <MusicOnIcon /> : <MusicOffIcon />}
            </ToolTip>
        </li>
    )
}

function mapStateToProps(state) {
    return {
        isOn: state.backgroundMusic
    }
}

export default connect(mapStateToProps, null)(GameMusic)