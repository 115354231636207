import React, {useEffect, useMemo, useRef, useState} from "react";
import ToolTipContent from "@components/toolTip/toolTipContent";

function ToolTip({children, title, position,  type = "tooltip", triggerCallBack = () => {return ""}, className=""}) {
    const toolTipRef = useRef();
    const [toolTipParams, setToolTipParams] = useState({})
    const [isMouseEnter, setIsMouseEnter] = useState(false)

    const getElementCoordinates = () => {
        if (toolTipRef && toolTipRef.current) {
            const tooltipParams = toolTipRef.current.getBoundingClientRect();
            setToolTipParams(tooltipParams)
        }
    }

    useEffect(() => {
        getElementCoordinates()


    }, [])


    const changeShowChild = useMemo(() => (e) => {
        // setIsMouseEnter(false);
    }, []);

    useEffect(() => {
        if (isMouseEnter) {
            // document.addEventListener('click', changeShowChild, false);
            document.addEventListener('click', changeShowChild, true);
            window.addEventListener("resize", getElementCoordinates, true);
        } else {
            document.removeEventListener('click', changeShowChild);
            window.removeEventListener('resize', getElementCoordinates, true);
        }
        triggerCallBack(isMouseEnter)
        requestAnimationFrame(() => {
            window.scrollTo(0, 1)
        });
    }, [isMouseEnter]);

    return(<div ref={toolTipRef} className="tooltip-wrapper "
                onMouseEnter={() => {
                    if (type ==="tooltip") {
                        setIsMouseEnter(true); getElementCoordinates()
                    }
                   }
                }
                onMouseLeave={() => {
                    if (type ==="tooltip") {
                        setIsMouseEnter(false)
                    }
                }}
                onMouseDown={() => {
                    if (type ==="tooltip") {
                        setIsMouseEnter(false)
                    }
                }}
                onClick={() => {
                    if ( type ==="popup" ) {
                        setIsMouseEnter(prev => !prev); getElementCoordinates()
                    }
                   }}>
        {children}
        {
            isMouseEnter ? <ToolTipContent title={title} toolTipParams={toolTipParams} position={position} type={type} 
            className={className} /> : ""
        }
    </div>)
}

export default ToolTip