import React, {useState} from "react";
import {connect} from "react-redux";
import {setVolum, setMusic} from "@actions/soundMusicAction";
import { getLocalStorage, setLocalStorage } from "@services/storageService";
import {MusicOnIcon, MusicOffIcon} from "@resource";

function VolumController({setVolum, setMusic, isOn}) {
    const [volume, changeVolume] = useState(getLocalStorage("volume") || 0)

    const handleChangeVolum = (value) => {
        changeVolume(value)
        setVolum(value)
        setLocalStorage("volume", value)
    }

    return ( 
    <div className="settings-box-sound-range" onClick={(e) =>  e.stopPropagation()}>
        <div className="icon" 
            onClick={() => setMusic(!isOn)}>
            {isOn ? <MusicOnIcon /> : <MusicOffIcon />}
        </div>
        <input type="range" min={0} max={1} step={0.001} onChange={({target}) => handleChangeVolum(target.value)} defaultValue={volume}/>
    </div>)
}


function mapStateToProps(state) {
    return {
        isOn: state.backgroundMusic
    }
}


const mapDispatchToProps = {
    setVolum,
    setMusic
}

export default connect(mapStateToProps, mapDispatchToProps)(VolumController)