import {SET_ODDS, SET_TIME_TICKER, SUB_ODDS} from "@actions/actionTypes";
import { getRealOdd } from "@services/oddService";
import {PlayerOddType} from "@constants/Enums";

export function subscribeOdds() {
    return dispatch => {
        dispatch({
            eventOn: SUB_ODDS,
            handle: (data) => {
                const odds = getRealOdd({Player: data.Player, Lay: data.Lay, Back: data.Back});
                dispatch({type: SET_ODDS, odds, pair:  PlayerOddType.None});
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Timer?.Start || 0, End: data.Timer?.End || 0}});
            }
        })
    }
}