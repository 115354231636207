import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import ErrImg from "@assets/img/icons/alertMessageIcons/error.svg";

import {FormattedMessage} from "react-intl";

const images = {
    'done': ErrImg,
    'error': ErrImg,
    'warning': ErrImg,
    'info': ErrImg
}


const AlertMessages = ({alertMessage}) => {
    const id = useRef(0)
    const refMap = useRef({})
    const timeout = useRef({})
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (alertMessage.messages) {
            id.current++
            let key = id.current + alertMessage.messages

            const index = messages.findIndex(msg => msg.messages === alertMessage.messages)
            if (index > -1) {

                let clonedMsg = JSON.parse(JSON.stringify(messages))
                clonedMsg.map((msg,idx) => {
                    if (idx === index) {
                        clearTimeout(timeout.current[msg.key])
                        timeout.current[msg.key] = setTimeout(() => {remove(msg.key)}, 5000)
                        msg.isUpdated= true
                    }
                })
                setMessages(clonedMsg)
            } else {
                timeout.current[key] = setTimeout(() => {remove(key)}, 7000)
                setMessages(prev => [{...alertMessage, key, isUpdated: false},...prev]);
            }
        }
    },[alertMessage])

    useEffect(() => {

        if (messages.length) {
            const clonedMsg = JSON.parse(JSON.stringify(messages))
            let updateState = false;
            clonedMsg.map(msg => {
                const element = refMap.current[msg.key]
                element.classList.add("in")
                setTimeout(() => {
                        element.classList.remove("out")
                    },1000
                )
                if (msg.isUpdated) {
                    element.classList.add("out")
                    element.classList.remove("in")
                    updateState = true
                }
                return msg.isUpdated = false
            })
            if (updateState) {
                setMessages(clonedMsg)
            }

        }
    }, [messages])

    const  remove = async (key) => {
        refMap.current[key].classList.add("out");
        refMap.current[key].classList.remove("in")
        // await new Promise((resolve) => setTimeout(resolve, 1000))
        clearTimeout(timeout.current[key])
        delete timeout.current[key]
        delete refMap.current[key]
        setMessages(prev => prev.filter(msg => msg.key !== key))
    }

    return(
        <div className={classNames('alertMessage', {"show": messages.length})}>
            {
                messages.map(msg => (
                    <div ref={(ref) => ref ? refMap.current[msg.key] = ref : ""} key={msg.key} className={classNames('msg in', msg.type)}>
                        {msg.type ?  <div className="icon" ><img src={images[msg.type]} alt="img"/></div>: ""}
                        {msg.messages ? <div className="text"><FormattedMessage id={msg.messages}/></div> : ""}
                    </div>
                ))
            }
        </div>
    )


}


function mapStateToProps(state) {
    return {
        alertMessage: state.alertMessage,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessages);
