import React from "react";
import {connect} from "react-redux";
import NominalItem from "@components/Nominal/NominalItem";

function Nominal({nominal, addValue, disabled}) {
    return (
        <div className="nominal">
            <ul className="nominal-list scrollable">
                {
                    nominal.map((item, index) => <NominalItem item={item} key={index} addValue={addValue} disabled={disabled}/>)
                }
            </ul>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        nominal: state.limit.Nominal
    }
}

export default connect(mapStateToProps, null)(Nominal)