import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {LoadingIcon} from "@resource";

var nanoPlayer;
var config;


function VideStream({videoStreamConfig}) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (videoStreamConfig && videoStreamConfig.Live) {
            const serverConfig =  JSON.parse(videoStreamConfig.Live);
            config = {
                "source": {
                    "defaults": {
                        "service": "bintu"
                    },
                    "startIndex": 0,
                    "entries": [
                        {
                            "index": 0,
                            "h5live": {
                                "server": {
                                    "websocket": "wss://bintu-h5live-secure.nanocosmos.de:443/h5live/authstream/stream.mp4",
                                    "hls": "https://bintu-h5live-secure.nanocosmos.de:443/h5live/authhttp/playlist.m3u8"
                                },
                                "rtmp": {
                                    "url": "rtmp://bintu-splay.nanocosmos.de:1935/splay",
                                    "streamname": videoStreamConfig.StreamName
                                },
                               /* "server": {
                                    "websocket": process.env.REACT_APP_STREAM_WEB_SOCKET,
                                    "hls": process.env.REACT_APP_STREAM_HLS,
                                },
                                "rtmp": {
                                    "url": process.env.REACT_APP_STREAM_COMMON_URL,
                                    "streamname": videoStreamConfig.StreamName
                                },*/
                                ...serverConfig.h5live
                            }
                        }
                    ]
                },
                "playback": {
                    "autoplay": true,
                    "automute": true,
                    "muted": true,
                },
                "style": {
                    "controls": false,
                    "view": false,
                    "width": "100%",
                    "height": "100%",
                    "displayMutedAutoplay": true
                },
                events: {
                    onReady: function (e) {
                        // setLoading(false)
                    },
                    onPlay: function (e) {
                        setLoading(false)         
                    },
                    onPause: function (e) {
                        setLoading(true)
                    },
                    onLoading: function (e) {
                        setLoading(true)
                    },
                    onStartBuffering: function (e) {
                        // setLoading(false)
                    },
                    onStopBuffering: function (e) {
                        // setLoading(true)
                    },
                    onWarning: function (e) {
                        //console.log(e);
                    },
                    onError: function (e) {
                        setLoading(true)
                        setTimeout(function(){ startPlayer(); }, 2000);
                    },
                    onDestroy: function (e) {
                        setLoading(true)
                    }
                }
            };
            nanoPlayer = new window.NanoPlayer('video-stream');
            nanoPlayer.setup(config).then(function (config) {}, function (error) {
                console.error(error.message);
            });
        }
    }, [videoStreamConfig])

    return(<div className="video-stream"  >
        <div id="video-stream" />
        { loading ? <div className="video-stream-loading"><LoadingIcon/> </div> : ""}
    </div>)
}

function startPlayer(){
    var mainDiv = document.getElementById("video-stream");
    nanoPlayer.setup(config).then(function (config) {
        var video = mainDiv.querySelector("video");
        // console.log('[VIDEO] ::', video)
       // if(video)
         //   video.setAttribute("poster", staticUrl + "videoplayer/nplayer/js/videobg.jpg");
      //  console.log("setup success");
       // console.log("config: " + JSON.stringify(config, undefined, 4));
    }, function (error) {
       // alert(error.message);
    });
}

const mapStateToProps = (state) => {
    return {
        videoStreamConfig: state.videoStreamConfig,
    }
}

export default connect(mapStateToProps, null)(VideStream)