import React from "react";
import classNames from "classnames";
import { Prediction, Type } from "@constants/Enums";
import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";

function LargeBoard({
    odds,
    disabledAll,
    enabledBetAreas,
    selectedBoard,
    selectBoard,
}) {
    const oddsData = odds;
    let Back8 = "", Lay8 = "", Back9 = "", Lay9 = "", Back10 = "", Lay10 = "", Back11 = "", Lay11 = "";
    const [Player8back, setPlayer8Back] = useState("");
    const [Player8lay, setPlayer8Lay] = useState("");
    const [Player9back, setPlayer9Back] = useState("");
    const [Player9lay, setPlayer9Lay] = useState("");
    const [Player10back, setPlayer10Back] = useState("");
    const [Player10lay, setPlayer10Lay] = useState("");
    const [Player11back, setPlayer11Back] = useState("");
    const [Player11lay, setPlayer11Lay] = useState("");
    // console.log(oddsData);
    useEffect(() => {
        if(oddsData[0] != undefined ){
            oddsData.forEach((element) => {
                if(element.Player == 0)
                {
                    Back8 = element.Back;
                    Lay8 = element.Lay;
                    // setPlayer8Back(element.Back);
                    // setPlayer8Lay(element.Lay);
                }
                if(element.Player == 1)
                {
                    Back9 = element.Back;
                    Lay9 = element.Lay;
                    // setPlayer9Back(element.Back);
                    // setPlayer9Lay(element.Lay);
                }
                if(element.Player == 2)
                {
                    Back10 = element.Back;
                    Lay10 = element.Lay;
                    // setPlayer10Back(element.Back);
                    // setPlayer10Lay(element.Lay);
                }
                if(element.Player == 3)
                {
                    Back11 = element.Back;
                    Lay11 = element.Lay;
                    // setPlayer11Back(element.Back);
                    // setPlayer11Lay(element.Lay);
                }
            });
        }
    setPlayer8Back(Back8);
    setPlayer8Lay(Lay8);
    setPlayer9Back(Back9);
    setPlayer9Lay(Lay9);
    setPlayer10Back(Back10);
    setPlayer10Lay(Lay10);
    setPlayer11Back(Back11);
    setPlayer11Lay(Lay11);
    }, [oddsData]);

    return (
        <svg
            id="Component_454_1"
            data-name="Component 454 – 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1013 155"
        >
            <g
                id="Group_4343"
                data-name="Group 4343"
                transform="translate(20 65)"
            >
                <g
                    id="Group_4341"
                    data-name="Group 4341"
                    transform="translate(-11 -17.002)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player8back == "",
                        active: Player8back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player8Back, Player8back)}
                >
                    <path
                        id="Rectangle_12505"
                        data-name="Rectangle 12505"
                        d="M8,0H80a0,0,0,0,1,0,0V40a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                        transform="translate(-9 10.002)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player8back || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4344"
                    data-name="Group 4344"
                    transform="translate(127 -17.002)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player8lay == "",
                        active: Player8lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player8Lay, Player8lay)}
                >
                    <path
                        id="Rectangle_12505-2"
                        data-name="Rectangle 12505"
                        d="M0,0H72a8,8,0,0,1,8,8V32a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                        transform="translate(-9 10.002)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-2"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player8lay || ""}
                        </tspan>
                    </text>
                </g>
                <text
                    id="_8"
                    className="fontcaptain"
                    data-name="8"
                    transform="translate(89 60)"
                    fill="#fff"
                    fontSize="130"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-29.315" y="0">
                        8
                    </tspan>
                </text>
            </g>
            <g
                id="Group_4345"
                data-name="Group 4345"
                transform="translate(263 65)"
            >
                <g
                    id="Group_4341-2"
                    data-name="Group 4341"
                    transform="translate(-11 -17.002)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player9back == "",
                        active: Player9back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player9Back, Player9back)}
                >
                    <path
                        id="Rectangle_12505-3"
                        data-name="Rectangle 12505"
                        d="M8,0H80a0,0,0,0,1,0,0V40a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                        transform="translate(-9 10.002)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-3"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player9back || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4344-2"
                    data-name="Group 4344"
                    transform="translate(127 -17.002)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player9lay == "",
                        active: Player9lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player9Lay, Player9lay)}
                >
                    <path
                        id="Rectangle_12505-4"
                        data-name="Rectangle 12505"
                        d="M0,0H72a8,8,0,0,1,8,8V32a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                        transform="translate(-9 10.002)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-4"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player9lay || ""}
                        </tspan>
                    </text>
                </g>
                <text
                    id="_9"
                    className="fontcaptain"
                    data-name="9"
                    transform="translate(89 60)"
                    fill="#fff"
                    fontSize="130"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-27.82" y="0">
                        9
                    </tspan>
                </text>
            </g>
            <g
                id="Group_4346"
                data-name="Group 4346"
                transform="translate(527 65)"
            >
                <g
                    id="Group_4341-3"
                    data-name="Group 4341"
                    transform="translate(-32 -17.002)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player10back == "",
                        active: Player10back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player10Back, Player10back)}
                >
                    <path
                        id="Rectangle_12505-5"
                        data-name="Rectangle 12505"
                        d="M8,0H80a0,0,0,0,1,0,0V40a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                        transform="translate(-9 10.002)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-5"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player10back || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4344-3"
                    data-name="Group 4344"
                    transform="translate(148 -17.002)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player10lay == "",
                        active: Player10lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player10Lay, Player10lay)}
                >
                    <path
                        id="Rectangle_12505-6"
                        data-name="Rectangle 12505"
                        d="M0,0H72a8,8,0,0,1,8,8V32a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                        transform="translate(-9 10.002)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-6"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player10lay || ""}
                        </tspan>
                    </text>
                </g>
                <text
                    id="_10"
                    className="fontcaptain"
                    data-name="10"
                    transform="translate(89 60)"
                    fill="#fff"
                    fontSize="130"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-49.79" y="0">
                        10
                    </tspan>
                </text>
            </g>
            <g
                id="Group_4347"
                data-name="Group 4347"
                transform="translate(803 65)"
            >
                <g
                    id="Group_4341-4"
                    data-name="Group 4341"
                    transform="translate(-23 -17.002)"
                    className={classNames("back board-svg-item",{
                        disabled: disabledAll || Player11back == "",
                        active: Player11back != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player11Back, Player11back)}
                >
                    <path
                        id="Rectangle_12505-7"
                        data-name="Rectangle 12505"
                        d="M8,0H80a0,0,0,0,1,0,0V40a0,0,0,0,1,0,0H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                        transform="translate(-9 10.002)"
                        fill="#72bbef"
                    />
                    <text
                        id="_99.9-7"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player11back || ""}
                        </tspan>
                    </text>
                </g>
                <g
                    id="Group_4344-4"
                    data-name="Group 4344"
                    transform="translate(139 -17.002)"
                    className={classNames("lay board-svg-item",{
                        disabled: disabledAll || Player11lay == "",
                        active: Player11lay != ""
                    })}
                    onClick={() => selectBoard(Prediction.Player11Lay, Player11lay)}
                >
                    <path
                        id="Rectangle_12505-8"
                        data-name="Rectangle 12505"
                        d="M0,0H72a8,8,0,0,1,8,8V32a8,8,0,0,1-8,8H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                        transform="translate(-9 10.002)"
                        fill="#f994ba"
                    />
                    <text
                        id="_99.9-8"
                        data-name="99.9"
                        transform="translate(31 37.002)"
                        fontSize="18"
                        fontFamily="SFProText-Bold, SF Pro Text"
                        fontWeight="700"
                        letterSpacing="0.04em"
                    >
                        <tspan x="-21.831" y="0">
                        { Player11lay || ""}
                        </tspan>
                    </text>
                </g>
                <text
                    id="_11"
                    className="fontcaptain"
                    data-name="11"
                    transform="translate(89 60)"
                    fill="#fff"
                    fontSize="130"
                    fontFamily="American Captain"
                    letterSpacing="0.04em"
                >
                    <tspan x="-40.56" y="0">
                        11
                    </tspan>
                </text>
            </g>
            <line
                id="Line_652"
                data-name="Line 652"
                y2="83"
                transform="translate(230.5 36.5)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
            />
            <line
                id="Line_653"
                data-name="Line 653"
                y2="93"
                transform="translate(230.5 31.5)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
            />
            <line
                id="Line_654"
                data-name="Line 654"
                y2="93"
                transform="translate(473.5 32)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
            />
            <line
                id="Line_655"
                data-name="Line 655"
                y2="93"
                transform="translate(758.5 31)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
            />
        </svg>
    );
}

export default LargeBoard;
