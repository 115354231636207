import React, { useEffect, useState } from "react";
import {FormattedMessage} from "react-intl";
import heartIcon from "@assets/img/icons/suits/heart.svg";
import diamondIcon from "@assets/img/icons/suits/diamond.svg";
import clubIcon from "@assets/img/icons/suits/club.svg";
import spadeIcon from "@assets/img/icons/suits/spade.svg";
import classNames from "classnames";


function Loading({load, setSoundKey }) {
    const [prevLoad, setPrevLoad] = useState(0);
    useEffect(() => {
        document.querySelector("animate").beginElement();
        if(load > 99) {
            setSoundKey("welcomeSound")
        }
        return () => {
            setPrevLoad(load);
        }
    }, [load])

    return (
        <div className="loading">
            <div className="border-box">
                <span className="border-left-right"/>
                <span className="border-top-bottom"/>
                <div className="loading-info-box">
                    <div className="title"><FormattedMessage id="welcome_text"/></div>
                    <div className="loader">
                        <div className="icon-box">
                            <div className={classNames("icon", {active: load > 0})}><img src={spadeIcon} alt="spadeIcon"/></div>
                            <div className={classNames("icon", {active: load > 25})}><img src={diamondIcon} alt="diamondIcon"/></div>
                            <div className={classNames("icon", {active: load > 50})}><img src={clubIcon} alt="clubIcon"/></div>
                            <div className={classNames("icon", {active: load > 75})}><img src={heartIcon} alt="heartIcon"/></div>
                        </div>
                        <svg  id="loader" xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="20px" viewBox="0 0 1000 20">
                            <line className="dashed content" x1="1%" y1="7" x2="99%" y2="7" stroke="#FFCC0033" strokeLinecap="round"/>
                            <line className="dashed load-part" x1="1%" y1="7" y2="7" x2={`${load}%`}  stroke="#FFCC00" strokeLinecap="round" >
                                <animate  attributeName="x2" from={`${prevLoad}%`} to={`${load}%`}   dur="0.5s"/>
                            </line>
                        </svg>
                        <div className="description"><FormattedMessage id="loading_game_and_table_data"/></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Loading