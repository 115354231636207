import React, {useEffect, useState} from "react";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import classNames from "classnames";
import {CardsSuit, CardTypeMap} from "@constants/Enums";

function CardsListItem({card, ...restProps}) {
    const [cardItem, setCardItem] = useState({
        Suit: 0,
        Type: 0,
        suit: 0,
        type: 0
    })
    

    useEffect(() => {
        let timer = setTimeout(() => {
            setCardItem(card);
        }, 500)
        return () => {
            clearTimeout(timer);
        }
    }, [card])
    return(

        <li className={classNames("cards-list-item card", {show: (card && card.Type) || (card && card.type) })} {...restProps}>
            <div className="card_number_title">
                <div className={classNames("flip-card", {active: (cardItem && cardItem.Type) || (cardItem && cardItem.type) })}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src={cardBg} alt={cardBg} />
                        </div>
                        <div className="flip-card-back">
                            <img src={openedCardBg} alt={openedCardBg} />
                            <i className={`cardIcon icon-${cardItem && CardTypeMap[cardItem.Type ? cardItem.Type : cardItem.type]}${cardItem && CardsSuit[cardItem.Suit ? cardItem.Suit : cardItem.suit]}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default CardsListItem;