import React, { useEffect, useState } from "react";
import { gameHistory } from "@constants/Enums";
import { HistoryTotalIcon } from "@resource";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";

function GameHistoryHeader({ history }) {
    const [total, setTotal] = useState(0);
    const [winPlayer8, setWinPlayer8] = useState(0);
    const [winPlayer9, setWinPlayer9] = useState(0);
    const [winPlayer10, setWinPlayer10] = useState(0);
    const [winPlayer11, setWinPlayer11] = useState(0);
    const [tie, setTie] = useState(0);
    useEffect(() => {
        let winPlayer8 = 0,
            winPlayer9 = 0,
            winPlayer10 = 0,
            winPlayer11 = 0,
            tie = 0;
        history.forEach((item) => {
            if (item.Won === gameHistory[8]) {
                winPlayer8++;
            }

            if (item.Won === gameHistory[9]) {
                winPlayer9++;
            }

            if (item.Won === gameHistory[10]) {
                winPlayer10++;
            }

            if (item.Won === gameHistory[11]) {
                winPlayer11++;
            }

            if (item.Won === gameHistory.T) {
                tie++;
            }
        });
        setTotal(history.length);
        setTie(tie);
        setWinPlayer8(winPlayer8);
        setWinPlayer9(winPlayer9);
        setWinPlayer10(winPlayer10);
        setWinPlayer11(winPlayer11);
    }, [history]);
    return (
        <div className="game-history-header">
            <ul className="list">
                <li className="list-item">
                    <div className="icon">
                        <HistoryTotalIcon />
                    </div>{" "}
                    {total}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory[8]} type="small" />{" "}
                    {winPlayer8}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory[9]} type="small" />{" "}
                    {winPlayer9}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory[10]} type="small" />{" "}
                    {winPlayer10}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory[11]} type="small" />{" "}
                    {winPlayer11}
                </li>
                {/* <li className="list-item">
                    <GameHistoryIcon won={gameHistory.T} type="small" /> {tie}
                </li> */}
            </ul>
        </div>
    );
}

export default GameHistoryHeader;
