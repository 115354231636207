import {PlayerOddType, Prediction} from "@constants/Enums";

export function getRealOdd(oddsValue) {
    // const [Player, Back, Lay] =  oddsValue;
    var odds = {};
    odds = oddsValue;
    // var obj = [{Player: 0, Test1: 12.2, Test2: 13.7},
    //                      {Player: 1, Test1: 5.95, Test2: 6.45},
    //                      {Player: 2, Test1: 3.2, Test2: 3.45},
    //                      {Player: 4, Test1: 2.08, Test2: 2.18}];
    
    // console.log(arr[0]['obj']);return;
    // Object.keys(Data).forEach( function (key) { 
        // console.log(Data[key]);
        // odds = {'Player':Data[key].Player, 'Back':Data[key].Back , 'Lay':Data[key].Lay};
    // });

    // for(var key in oddsValue)
    // {
    //     // if (!oddsValue.hasOwnProperty(key)) continue;
    //     odds[oddsValue[key].Player] = {'Back':oddsValue[key].Back,'Lay':oddsValue[key].Lay};
    // }
    
    return odds;
}