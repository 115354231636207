import {SET_GAME_HISTORY} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function gameHistoryReducer(state = initialStore.gameHistory, action) {
    switch (action.type) {
        case SET_GAME_HISTORY:
            return {
                history : action.gameHistory.RoundModels.Items,
                Player8Percentage: action.gameHistory.Player8Percentage,
                Player9Percentage: action.gameHistory.Player9Percentage,
                Player10Percentage: action.gameHistory.Player10Percentage,
                Player11Percentage: action.gameHistory.Player11Percentage
            }
        default:
            return state;
    }
}