import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetPlayingCards, subscribeCards } from "@actions/cardsAction";
import CardsList from "@components/Cards/CardsList";
import { FormattedMessage } from "react-intl";
import CardsItem from "@components/Cards/CardsItem";

function Cards({ cards, gameCondition, resetPlayingCards, subscribeCards }) {
    // const cards8 = {player8Hand: {Cards: [{Type:10,Suit:3,Code:"GK"},{Type:6,Suit:3,Code:"FU"},{Type:7,Suit:3,Code:"FU"},{Type:8,Suit:3,Code:"FU"}], CardsWeight: 24}}
    // const cards9 = {player9Hand: {Cards: [{Type:9,Suit:3,Code:"GK"}], CardsWeight: 18}}
    // const cards10 = {player10Hand: {Cards: [{Type:6,Suit:3,Code:"FU"}], CardsWeight: 16}}
    // const cards11 = {player11Hand: {Cards: [{Type:7,Suit:1,Code:"GA"},{Type:13,Suit:1,Code:"GY"}], CardsWeight: 31}}
    const [player8CardList, setPlayer8CardList] = useState([]);
    const [player9CardList, setPlayer9CardList] = useState([]);
    const [player10CardList, setPlayer10CardList] = useState([]);
    const [player11CardList, setPlayer11CardList] = useState([]);

    useEffect(() => {
        setPlayer8CardList(cards.player8Hand)
        setPlayer9CardList(cards.player9Hand)
        setPlayer10CardList(cards.player10Hand)
        setPlayer11CardList(cards.player11Hand)
    }, [cards]);

    useEffect(() => {
        subscribeCards();
    }, []);

    useEffect(() => {
        if (!gameCondition) {
            resetPlayingCards();
        }
    }, [gameCondition]);

    return (
        <div>
            <div className="cards cards-32">
                <CardsList cardList={player8CardList} title={<FormattedMessage id="player 8"  />} cardNumber="8" />
                <CardsList cardList={player9CardList} title={<FormattedMessage id="player 9"  />} cardNumber="9"/>
                <CardsList cardList={player10CardList} title={<FormattedMessage id="player 10" />} cardNumber="10"/>
                <CardsList cardList={player11CardList} title={<FormattedMessage id="player 11" />} cardNumber="11"/>
            </div>
           
            <div className="cards-mobile">
                <div className="cards-element">
                    <div className="cards-top-box">
                        <div className="title">{player8CardList && player8CardList.CardsWeight ? player8CardList.CardsWeight : ''}</div>
                    </div>
                    <div className="cards-middle-box">
                        <ul className="cards-list">
                            <CardsItem card={player8CardList && player8CardList.Cards ? player8CardList.Cards[player8CardList.Cards.length -1] : []} />   
                        </ul>
                    </div>
                    <div className="cards-bottom-box">
                        <div className="title">p-8</div>
                    </div>
                </div>
                <div className="cards-element">
                    <div className="cards-top-box">
                        <div className="title">{player9CardList && player9CardList.CardsWeight ? player9CardList.CardsWeight : ''}</div>
                    </div>
                    <div className="cards-middle-box">
                        <ul className="cards-list">
                            <CardsItem card={player9CardList && player9CardList.Cards ? player9CardList.Cards[player9CardList.Cards.length -1] : []} />   
                        </ul>
                    </div>
                    <div className="cards-bottom-box">
                        <div className="title">p-9</div>
                    </div>
                </div>
                <div className="cards-element">
                    <div className="cards-top-box">
                        <div className="title">{player10CardList && player10CardList.CardsWeight ? player10CardList.CardsWeight : ''}</div>
                    </div>
                    <div className="cards-middle-box">
                        <ul className="cards-list">
                            <CardsItem card={player10CardList && player10CardList.Cards ? player10CardList.Cards[player10CardList.Cards.length -1] : []} />   
                        </ul>
                    </div>
                    <div className="cards-bottom-box">
                        <div className="title">p-10</div>
                    </div>
                </div>
                <div className="cards-element">
                    <div className="cards-top-box">
                        <div className="title">{player11CardList && player11CardList.CardsWeight ? player11CardList.CardsWeight : ''}</div>
                    </div>
                    <div className="cards-middle-box">
                        <ul className="cards-list">
                            <CardsItem card={player11CardList && player11CardList.Cards ? player11CardList.Cards[player11CardList.Cards.length-1] : []} />   
                        </ul>
                    </div>
                    <div className="cards-bottom-box">
                        <div className="title">p-11</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const mapStateToProps = (state) => {
    return {
        cards: state.cards,
        gameCondition: state.gameCondition,
    };
};

const mapDispatchToProps = {
    subscribeCards,
    resetPlayingCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
