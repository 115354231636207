import React, { useState } from "react";
import classNames from "classnames";
import { gameHistory } from "@constants/Enums";

function GameHistoryIcon({ won, type, active }) {
    return (
        <div
            className={classNames("game-history-icon", type, "player-"+gameHistory[won], {
                active: active,
            })}
        >
            {gameHistory[won]}
        </div>
    );
}

export default GameHistoryIcon;
