import React, {useEffect} from "react";
import {connect} from "react-redux";
import {subscribeLoadInfo} from "@actions/betsAction";
import classNames from "classnames";

function Load({subscribeLoadInfo, loadInfo, threeD}) {
    useEffect(() => {
        subscribeLoadInfo()
    }, [])
    return (
        <div className={classNames("load", {threeD})}>
            <div className={classNames("load-info", {green: loadInfo["A"] >= 0 , red: loadInfo["A"] < 0  })}>
                {loadInfo["A"]  ? <div className="load-info-box">{loadInfo["A"] > 0 ? "+" :"" }{loadInfo["A"] && Number(loadInfo["A"].toFixed(2))} </div> : ""}
            </div>
            <div className={classNames("load-info", {green: loadInfo["B"] >= 0 , red: loadInfo["B"] < 0  })}>
                {loadInfo["B"]  ? <div className="load-info-box">{loadInfo["B"] > 0 ? "+" :"" }{loadInfo["B"] && Number(loadInfo["B"].toFixed(2))}</div> : ""}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loadInfo: state.loadInfo,
    }
}

const mapDispatchToProps = {
    subscribeLoadInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Load)