import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
    CardsSuit,
    Prediction,
    PlayerOddType,
    BetsType,
} from "@constants/Enums";

import RoundInfoItem from "./RoundInfoItem";
import CardList from "./CardList";
import { floatNumberWithSpaces } from "@services/numberFormatServices";

export function RoundHistory({ round, getHistoryItemValue }) {
    console.log('round',round)
    const [player8Cards, setPlayer8Cards] = useState([]);
    const [player9Cards, setPlayer9Cards] = useState([]);
    const [player10Cards, setPlayer10Cards] = useState([]);
    const [player11Cards, setPlayer11Cards] = useState([]);
    useEffect(() => {
        let cardsEight = [],
            cardsNine = [],
            cardsTen = [],
            cardsEleven = [];
            if(round.player8Hand){
                cardsEight.push(round.player8Hand);
            }
            if(round.player9Hand){
                cardsNine.push(round.player9Hand);
            }
            if(round.player10Hand){
                cardsTen.push(round.player10Hand);
            }
            if(round.player11Hand){
                cardsEleven.push(round.player11Hand);
            }
            setPlayer8Cards(cardsEight[0]);
            setPlayer9Cards(cardsNine[0]);
            setPlayer10Cards(cardsTen[0]);
            setPlayer11Cards(cardsEleven[0]);
    //     let winPlayer8 = 0,
    //         winPlayer9 = 0,
    //         winPlayer10 = 0,
    //         winPlayer11 = 0,
    //         tie = 0;
    //     round.forEach((item) => {
    //         if (item.Won === gameHistory[8]) {
    //             winPlayer8++;
    //         }

    //         if (item.Won === gameHistory[9]) {
    //             winPlayer9++;
    //         }

    //         if (item.Won === gameHistory[10]) {
    //             winPlayer10++;
    //         }

    //         if (item.Won === gameHistory[11]) {
    //             winPlayer11++;
    //         }
    //     });
    //     setPlayer8Cards(winPlayer8);
    //     setPlayer9Cards(winPlayer9);
    //     setPlayer10Cards(winPlayer10);
    //     setPlayer11Cards(winPlayer11);
    }, [round]);

    return (
        <div className="history-round scrollable">
            <div className="history-round-info">
                <RoundInfoItem
                    time={round.startTime}
                    timeTitle="game_start"
                    balance={round.balanceBefore}
                    balanceTitle="balance_before_the_round"
                />
                <RoundInfoItem
                    time={round.endTime}
                    timeTitle="game_end"
                    balance={round.balanceAfter}
                    balanceTitle="balance_after_the_round"
                />
            </div>
            <div className="round-cards">
                <CardList
                    player={<FormattedMessage id="player_8" />}
                    cards={player8Cards}
                    winner={
                        round.won === PlayerOddType.Player8 ||
                        round.won === PlayerOddType.Both
                    }
                />

                <CardList
                    player={<FormattedMessage id="player_9" />}
                    cards={player9Cards}
                    winner={
                        round.won === PlayerOddType.Player9 ||
                        round.won === PlayerOddType.Both
                    }
                />
                <CardList
                    player={<FormattedMessage id="player_10" />}
                    cards={player10Cards}
                    winner={
                        round.won === PlayerOddType.Player10 ||
                        round.won === PlayerOddType.Both
                    }
                />

                <CardList
                    player={<FormattedMessage id="player_11" />}
                    cards={player11Cards}
                    winner={
                        round.won === PlayerOddType.Player11 ||
                        round.won === PlayerOddType.Both
                    }
                />
            </div>
            <div className="round-bets">
                <div className="tbl">
                    <div className="row">
                        <div className="cell">
                            <FormattedMessage id="bet_type" />
                        </div>
                        <div className="cell">
                            <FormattedMessage id="bet" />
                        </div>
                        <div className="cell">
                            <FormattedMessage id="odd" />
                        </div>
                        <div className="cell">
                            <FormattedMessage id="win_lose" />
                        </div>
                    </div>
                    {round.bets.map((item, index) => (
                        <div className="row" key={index}>
                            <div className="cell">
                                <FormattedMessage
                                    id={Prediction[item.prediction]}
                                />
                            </div>
                            <div className="cell">
                                {floatNumberWithSpaces(item.stake)}
                            </div>
                            <div className="cell">
                                {floatNumberWithSpaces(item.odd)}
                            </div>

                            <div className="cell">
                                {floatNumberWithSpaces(
                                    getHistoryItemValue(item),
                                    0
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="row">
                        <div className="cell">
                            <FormattedMessage id="total" />:
                        </div>
                        <div className="cell">
                            {floatNumberWithSpaces(
                                round.bets.reduce((a, b) => b.stake + a, 0)
                            )}
                        </div>
                        <div className="cell"></div>
                        <div className="cell">
                            {floatNumberWithSpaces(
                                round.bets.reduce(
                                    (a, b) => getHistoryItemValue(b) + a,
                                    0
                                ),
                                0
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RoundHistory;
