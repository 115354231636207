import {
    SUB_BET,
    SET_ALERT_MSG,
    SET_LOAD_INFO,
    SUB_LOAD_INFO,
    SET_USER_BALANCE,
    SEND_BET,
    SET_BETS,
    SET_SELECTED_BOARD,
    RESET_SELECTED_BOARD,
    CREATE_BETS, SET_ALL_BETS
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@services/storageService";
import {getBetsList} from "@services/betService";

export function createBet(chip,board, odd) {
    return (dispatch, getState) => {
        const bets = {Prediction: board, Stake: +chip, Odd: odd};
        const {userInfo, roundId} = getState();
        if (userInfo && roundId) {
            const unAcceptedBets = getLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`) || [];
            const acceptedBets = getLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`) || [];
            const betsList = getBetsList(bets, unAcceptedBets)

            setLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`, betsList)
            dispatch({
                type: CREATE_BETS, bets: [...betsList, ...acceptedBets]
            })
        }
    }

}

export function addBet() {
    return (dispatch, getState) => {
        const {userInfo, roundId} = getState();
        const filteredBet = [];
        let bets = getLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`) || []
        bets.forEach(item => {
            filteredBet.push({
                Prediction: item.Prediction,
                Stake: item.Stake
            })
        })

        if (filteredBet.length) {
            dispatch({
                eventEmit: SEND_BET,
                emitData: [
                    ...filteredBet
                ],
                type: SEND_BET
            })
        }
    }
}

export function subscribeBetResult() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_BET,
            handle: (data) => {
                const {userInfo, roundId} = getState();
                let acceptedBets = getLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`) || [];
                dispatch({type: SET_USER_BALANCE, balance: data.Balance});
                if (Array.isArray(data.Bets)) {
                    var userBookData = [];
                    let bets = data.Bets.filter(bet => !bet.ErrorCode).map(item => {return  {
                        accepted: true,
                        Prediction: item.Prediction,
                        Stake: item.Stake,
                        Odd: item.Odd
                    }})
                    userBookData["Player8"] = data.Load["Player8"] ? data.Load["Player8"] : "";
                    userBookData["Player9"] = data.Load["Player9"] ? data.Load["Player9"] : "";
                    userBookData["Player10"] = data.Load["Player10"] ? data.Load["Player10"] : "";
                    userBookData["Player11"] = data.Load["Player11"] ? data.Load["Player11"] : "";
                    // bets.forEach(item => {
                    //     userBookData['prediction-'+item.Prediction] = item.Stake;
                    // })
                    dispatch({type: SET_LOAD_INFO, load: userBookData || initialStore.loadInfo})
                    removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`);
                    setLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`, [...acceptedBets, ...bets])
                    dispatch({type: SET_BETS, bets: [...bets, ...acceptedBets, ] });
                    data.Bets?.forEach(bet => {
                        if (bet.ErrorCode) {
                            dispatch({type: SET_ALERT_MSG, data: {type: 'error', messages: `error_${bet.ErrorCode}`}});
                        }
                    })
                }
            }
        })
    }
}

export function subscribeLoadInfo() {
    return dispatch => {
        dispatch({
            eventOn: SUB_LOAD_INFO,
            handle: (data) => {
                dispatch({type: SET_LOAD_INFO, load: data.Load || initialStore.loadInfo});
            }
        })
    }
}


export function selectBoard(board, odd) {
    return dispatch => {
        dispatch({type: SET_SELECTED_BOARD, board, odd})
    }
}

export function resetSelectBoard() {
    return dispatch => {
        dispatch({type: RESET_SELECTED_BOARD})
    }
}

export function resetBets() {
    return (dispatch, getState) => {
        const {userInfo, roundId} = getState();
        let acceptedBets = getLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`) || [];
        removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
        dispatch({type: SET_ALL_BETS, bets: acceptedBets})
    }
}