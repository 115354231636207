// export const DefaultLanguage = 'en-US';
export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];


export const BetsType = {
    1: "win",
    2: "lose",
    3: "refund",
    "win": 1,
    "lose": 2,
    "refund": 3
}

export const TimeTicker = {
    6: "yellow",
    3: "red",
    0: "white"
}

export const Prediction = {
    0: "Player8Back",
    1: "Player9Back",
    2: "Player10Back",
    3: "Player11Back",
    4: "Player8Lay",
    5: "Player9Lay",
    6: "Player10Lay",
    7: "Player11Lay",
    "Player8Back": 0,
    "Player9Back": 1,
    "Player10Back": 2,
    "Player11Back": 3,
    "Player8Lay": 4,
    "Player9Lay": 5,
    "Player10Lay": 6,
    "Player11Lay": 7,
}

export const SocketUpdateType = {
    0: "Ping",
    1: "Error",
    2: "RoundStart",
    3: "Card",
    4: "Odd",
    5: "Timer",
    6: "Result",
    7: "Bet",
    8: "Pair"
}

export const CardsSuit = {
    1: "clubs",
    2: "diamonds",
    3: "hearts",
    4: "spades"
}

export const CardTypeMap = {
    1: "A",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "A",
    15: "Joker1",
    16: "Joker2"
}

export const PlayerOddType = {
    0: "Player8",
    1: "Player9",
    2: "Player10",
    3: "Player11",
    "Player8" : 0,
    "Player9": 1,
    "Player10": 2,
    "Player11": 3
}

export const gameHistory = {
    1: "8",
    2: "9",
    3: "10",
    4: "11",
    "8": 1,
    "9": 2,
    "10": 3,
    "11": 4
}

export const SocketReadyState = {
    "CONNECTING": 0,
    "OPEN": 1,
    "CLOSING": 2,
    "CLOSED": 3
}

export const Type = {
    1: "Back",
    2: "Lay"
}